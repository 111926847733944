import React, {useEffect, useState} from 'react'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import {Link, navigate} from "gatsby"
import InputBase from '@material-ui/core/InputBase'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CircularProgress from "@material-ui/core/CircularProgress"
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    },
    width: '100%'
  },
  input: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    color: '#525f7f',
    fontSize: 16,
    marginBottom: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
}))(InputBase)

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%'
    },
    '& .MuiFormGroup-row': {
      alignItems: 'center'
    },
    '& .MuiFormControl-root': {
      backgroundColor: '#fff',
      border: 'none',
      borderRadius: 4,
      boxShadow: '0 1px 3px 0 rgba(50, 50, 93, 0.15)',
      display: 'block',
      marginBottom: 20
    },
    '& .MuiFormLabel-root': {
      '&.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.54)'
      }
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  lineItem: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  bottom: {
    color: '#999',
    animationDuration: '550ms',
    marginLeft: 10
  }
}))

const ShippingSection = ({data, onDeliveryChange}) => {
  const classes = useStyles()
  const [state, setState] = useState({
    email: null,
    phone: null,
    billingAddress: {
      name: '',
      street1: '',
      city: '',
      state: '',
      zip: '',
      country: 'US'
    },
    deliveryAddress: {
      name: '',
      street1: '',
      city: '',
      state: '',
      zip: '',
      country: 'US'
    },
    deliveryMethod: 'delivery',
    isValid: false,
    loading: false,
    sameAsBillingAddress: false,
    shippingId: '',
    shippingRate: null
  })

  useEffect(() => {
    const order = sessionStorage.getItem('order')
    console.log(JSON.parse(order))
    setState({...state, ...JSON.parse(order)})
    // const shippingMethods = sessionStorage.getItem('shippingMethods')
    // setShippingMethods(JSON.parse(shippingMethods))
  }, [])

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setState({
        ...state,
        [event.target.name]: event.target.checked,
        deliveryAddress: state.billingAddress,
        isValid: true
      })
    } else {
      setState({
        ...state,
        [event.target.name]: event.target.checked,
        deliveryAddress: {
          name: '',
          street1: '',
          city: '',
          state: '',
          zip: '',
          country: 'US'
        },
        isValid: false
      });
    }
  };
  const handleAddressChange = (event) => {
    const name = event.target.name
    const deliveryAddress = {
      ...state.deliveryAddress,
      [name]: event.target.value
    }
    const isValid = Object.values(deliveryAddress).every(x => x !== '')
    setState({
      ...state,
      deliveryAddress,
      isValid
    })
    onDeliveryChange({...state.deliveryAddress, ...state.deliveryMethod})
  }
  const handleDeliveryMethod = (event, deliveryMethod) => {
    let obj = {}
    if (deliveryMethod === 'pickup') {
      obj = {
        sameAsBillingAddress: false,
        deliveryAddress: {
          name: '',
          street1: '',
          city: '',
          state: '',
          zip: '',
          country: 'US'
        }
      }
    }
    setState({...state, ...obj, deliveryMethod});
  };
  /*
  const handleShipping = (event) => {
    console.log(event.target.value)
    const rate = shippingMethods.rates.find(x => x.object_id === event.target.value);
    // const total = (step2.subtotal + parseFloat(rate.amount) + (step2.subtotal * .0635)).toFixed(2).replace('.', '')
    // setStep2({...step2, rate: rate, shipping: rate.amount, salesTax: step2.subtotal * .0635, total: parseInt(total)})
    // setShippingRate(rate);
    setState({...state, shippingId: event.target.value, shippingRate: rate})
    // onShippingChange(rate);
  }
  */
  const handleSubmit = async () => {
    setState({...state, loading: true})

   const {deliveryAddress, deliveryMethod} = state
    const order = JSON.parse(sessionStorage.getItem('order'))
    const update = {
      ...order,
      deliveryAddress,
      deliveryMethod
    }
    sessionStorage.setItem('order', JSON.stringify(update))

    // const card = elements.getElement(CardElement)
    // const stripe = await stripePromise
    // const {token, error} = await stripe.createToken(card, {
    //   name: address.name,
    //   address_city: address.city,
    //   address_line1: address.address,
    //   address_state: address.state,
    //   address_country: 'US'
    // })
    // if (error) {
    //   // Inform the user if there was an error.
    //   setError(error.message)
    // } else {
    //   setError(null)
    //   const charge = JSON.stringify({
    //     token,
    //     charge: {
    //       amount: 50,
    //       currency: 'usd',
    //       email: address.email,
    //       number: address.phone
    //     }
    //   })
    //   // Send the token to your server.
    //   await stripeTokenHandler(charge)
    //   setSuccess(true)
    // }

    // let order = sessionStorage.getItem('order')
    // order = {...order, email: state.email, phone: state.phone, deliveryAddress: state.deliveryAddress};
    // sessionStorage.setItem('order', JSON.stringify(order))
    // sessionStorage.setItem('shippingMethods', JSON.stringify(results))
    navigate("/checkout/payment")
  }

  return (
      <div className={classes.root}>
        <Box marginBottom={5}>
          <FormControl>
            <Box className={classes.lineItem} style={{padding: 10}}>
              <Typography variant={'body1'}>Contact</Typography>
              <Typography variant={'body1'}>{state.email}</Typography>
              <Link to="/checkout/contactInfo">
                <Typography variant={'body1'}>Change</Typography>
              </Link>
            </Box>
            <hr style={{borderColor: 'rgba(50, 50, 93, 0.05)'}}/>
            <Box className={classes.lineItem} style={{padding: 10}}>
              <Typography variant={'body1'}>Billing Address</Typography>
              <Typography variant={'body1'}>{state.billingAddress.street1}, {state.billingAddress.city}, {state.billingAddress.state} {state.billingAddress.zip}</Typography>
              <Link to="/checkout/contactInfo">
                <Typography variant={'body1'}>Change</Typography>
              </Link>
            </Box>
          </FormControl>
        </Box>
        <Box marginBottom={5}>
          <Typography variant={'h6'} gutterBottom>Delivery Method</Typography>
          <ToggleButtonGroup
              size="small"
              value={state.deliveryMethod}
              exclusive
              onChange={handleDeliveryMethod}
              aria-label="delivery method"
          >
            <ToggleButton disableFocusRipple value="delivery" aria-label="delivery">
              Delivery
            </ToggleButton>
            <ToggleButton disableFocusRipple value="pickup" aria-label="pickup">
              Pickup
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box marginBottom={5}>
          {state.deliveryMethod === 'delivery' && (
              <Box marginBottom={5}>
                <Typography variant={'h6'} gutterBottom>Delivery Address</Typography>
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={state.sameAsBillingAddress}
                          onChange={handleCheckboxChange}
                          name="sameAsBillingAddress"
                          color="primary"
                      />
                    }
                    label="Same As Billing Address"
                />
                <FormLabel>
                  <BootstrapInput name='name' value={state.deliveryAddress.name} onChange={handleAddressChange} placeholder='Name' disabled={state.sameAsBillingAddress} required />
                </FormLabel>
                <FormLabel>
                  <BootstrapInput name='street1' value={state.deliveryAddress.street1} onChange={handleAddressChange} placeholder='Address' disabled={state.sameAsBillingAddress} required />
                </FormLabel>
                <FormLabel>
                  <BootstrapInput name='city' value={state.deliveryAddress.city} onChange={handleAddressChange} placeholder='City' disabled={state.sameAsBillingAddress} required />
                </FormLabel>
                <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
                  <Grid item sm={6}>
                    <FormLabel>
                      <BootstrapInput name='state' value={state.deliveryAddress.state} onChange={handleAddressChange} placeholder='State' disabled={state.sameAsBillingAddress} required />
                    </FormLabel>
                  </Grid>
                  <Grid item sm={6}>
                    <FormLabel>
                      <BootstrapInput name='zip' value={state.deliveryAddress.zip} onChange={handleAddressChange} placeholder='Postal code' disabled={state.sameAsBillingAddress} required />
                    </FormLabel>
                  </Grid>
                </Grid>
                {/*
        <FormLabel>
          <Typography variant={'inherit'}>Country</Typography>
          <NativeSelect
            id='demo-customized-select-native'
            onChange={onChange}
            input={<BootstrapInput />}
            defaultValue={'US'}
          >
            <option value='AU'>Australia</option>
            <option value='AT'>Austria</option>
            <option value='BE'>Belgium</option>
            <option value='BR'>Brazil</option>
            <option value='CA'>Canada</option>
            <option value='CN'>China</option>
            <option value='DK'>Denmark</option>
            <option value='FI'>Finland</option>
            <option value='FR'>France</option>
            <option value='DE'>Germany</option>
            <option value='HK'>Hong Kong</option>
            <option value='IE'>Ireland</option>
            <option value='IT'>Italy</option>
            <option value='JP'>Japan</option>
            <option value='LU'>Luxembourg</option>
            <option value='MY'>Malaysia</option>
            <option value='MX'>Mexico</option>
            <option value='NL'>Netherlands</option>
            <option value='NZ'>New Zealand</option>
            <option value='NO'>Norway</option>
            <option value='PL'>Poland</option>
            <option value='PT'>Portugal</option>
            <option value='SG'>Singapore</option>
            <option value='ES'>Spain</option>
            <option value='SE'>Sweden</option>
            <option value='CH'>Switzerland</option>
            <option value='GB'>United Kingdom</option>
            <option value='US'>United States</option>
          </NativeSelect>
        </FormLabel>
        */}
              </Box>
          )}
          {/*
          <Fragment>
                <FormControl>
                  <Select
                      value={state.shippingId}
                      displayEmpty
                      onChange={handleShipping}
                      input={<BootstrapInput />}
                  >
                    <MenuItem value="" disabled>
                      <Typography>Select...</Typography>
                    </MenuItem>
                    {shippingMethods && shippingMethods.rates.map(({object_id, amount, provider, provider_image_75, servicelevel}, index) => (
                        <MenuItem key={index} value={object_id}>
                          <Typography>{provider} {servicelevel.name}</Typography>
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography component={'small'}>Note: The shipping you selected, will occurs after a fulfillment order is complete</Typography>
              </Fragment>
          */}
        </Box>
        <Box marginBottom={5} align={'right'}>
          <Button variant={'contained'} color={'primary'} disabled={state.loading || (state.deliveryMethod === 'delivery' && !state.isValid)} onClick={handleSubmit}>
            Continue
            {state.loading && (
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    className={classes.bottom}
                    size={20}
                    thickness={4}
                />
            )}
          </Button>
        </Box>
      </div>
  )
}

export default ShippingSection
