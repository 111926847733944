import React, { useEffect, useState } from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Helmet from 'react-helmet'
import { Router } from '@reach/router'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import AddressSection from '../components/AddressSection'
import CardSection from '../components/CardSection'
import ShippingSection from '../components/ShippingSection'
import Success from '../components/Success'
import {formatCurrencyString, useShoppingCart} from '../context'
import { Elements } from '@stripe/react-stripe-js'
import {loadStripe} from "@stripe/stripe-js"

import Header from '../components/Header'
import Footer from '../components/Footer'

const stripe = loadStripe(process.env.GATSBY_STRIPE_CLIENT_KEY)

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%'
        },
        '& .MuiFormGroup-row': {
            alignItems: 'center'
        },
        '& .MuiFormControl-root': {
            backgroundColor: '#fff',
            border: 'none',
            borderRadius: 4,
            boxShadow: '0 1px 3px 0 rgba(50, 50, 93, 0.15)',
            display: 'block',
            marginBottom: 20
        },
        '& .MuiFormLabel-root': {
            '&.Mui-focused': {
                color: 'rgba(0, 0, 0, 0.54)'
            }
        }
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    gridItem: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 50
    },
    lineItem: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    }
}))

export default ({location}) => {
    const classes = useStyles()
    const [state, setState] = useState({
        email: "test@example.com",
        phone: "8603123445",
        total: 3972,
        billingAddress: {
            name: null,
            street1: null,
            city: null,
            state: null,
            country: 'US',
            zip: null
        },
        items: [{
            "name": "Mesh Banners",
            "image": "https://res.cloudinary.com/fitouch/image/upload/v1599675222/projects/corporate-banner_ygupow.png",
            "quantity": 10,
            "price": 2500,
            "metadata": {
                "color": "Red",
                "size": "10ft x 20ft",
                "files": ["https://res.cloudinary.com/fitouch/image/upload/v1599675222/projects/corporate-banner_ygupow.png", "https://res.cloudinary.com/fitouch/image/upload/v1589851565/projects/mp-smith-wesson-icon_r3aqlt.png"],
                "parcel": {
                    length: "8",
                    width: "8",
                    height: "50",
                    weight: "20",
                    distance_unit: "in",
                    mass_unit: "lb"
                }
            }
        }],
        shippingMethod: null
    })
    const [orderSummary, setOrderSummary] = useState({
        salesTax: 0,
        total: 0
    })
    const {totalPrice, cartDetails} = useShoppingCart()

    useEffect(() => {
        console.log(location.pathname)

        const checkUserData = () => {
            // const item = localStorage.getItem('userData')
            const order = sessionStorage.getItem('order')

            if (order) {
                // setUserData(item)
                console.log('Watched order', order)
                setState({...state, ...JSON.parse(order)})
            }
        }
        checkUserData()

        window.addEventListener('storage', checkUserData)

        return () => {
            window.removeEventListener('storage', checkUserData)
        }

    }, [])

    useEffect(() => {
        let salesTax = 0;
        let total = totalPrice;
        // state.items.map(({quantity, price}) => {
        //     subTotal += quantity * price;
        // })
        // total = subTotal;
        if (state.billingAddress.state) {
            console.log('Is CT? ', /(Connecticut|CT)/ig.test(state.billingAddress.state))
            if (/(Connecticut|CT)/ig.test(state.billingAddress.state)) {
                salesTax = parseInt(totalPrice * 0.0635)
                total += salesTax
            }
        }
        if (state.shippingMethod) {
            const shippingCost = parseInt(state.shippingMethod.amount.replace('.', ''))
            total += shippingCost
        }
        console.log('Total: ', total)

        const order = JSON.parse(sessionStorage.getItem('order'))
        const update = {...order, salesTax, total}
        sessionStorage.setItem('order', JSON.stringify(update))

        setOrderSummary({...orderSummary, salesTax, total})
    }, [state.billingAddress, state.shippingMethod, state.items])

    const handleAddressChange = (billingAddress) => {
        setState({
            ...state,
            billingAddress: billingAddress
        })
    }
    const handleDeliveryChange = (update) => {
        setState({
            ...state,
            ...update
        })
    }

  return (
      <div>
          <Helmet>
              <title>Checkout | Signxperts</title>
          </Helmet>
          <Header/>
          <Grid container>
              <Grid item sm={6} className={classes.gridItem}>
                  <Elements stripe={stripe}>
                      <Router basepath='/checkout'>
                          <AddressSection
                              path='/contactInfo'
                              data={{items: cartDetails}}
                              onChange={handleAddressChange}
                          />
                          <ShippingSection
                              path='/delivery-method'
                              data={state.shippingMethod}
                              onDeliveryChange={handleDeliveryChange}
                          />
                          <CardSection
                              path='/payment'
                              data={state}
                          />
                          <Success path='/thankyou' />
                      </Router>
                  </Elements>
              </Grid>
              <Grid item sm={6} className={classes.gridItem}>
                  <Box marginBottom={5}>
                      <Typography variant={'h5'} align={'center'}>Order Summary</Typography>
                  </Box>
                  <Box marginBottom={3}>
                      {Object.entries(cartDetails).map(([key, item], index) => (
                          <Box key={index} marginBottom={2}>
                              <Grid container alignItems={'center'}>
                                  <Grid item sm={3}>
                                      <img className='image' src={item.image} alt={item.name} width={'100%'} style={{marginRight: 10}} />
                                  </Grid>
                                  <Grid item sm={5}>
                                      <Typography variant={'h6'}>{item.name}</Typography>
                                      {item.metadata && Object.entries(item.metadata).map(([key, item], index) => (
                                          key !== 'files' && key !== 'parcel' && (
                                              <Box key={index} alignItems={'center'} display={'flex'}>
                                                  <Typography style={{marginRight: 10}}>{key}:</Typography>
                                                  <Typography>{item}</Typography>
                                              </Box>
                                          )
                                      ))}
                                  </Grid>
                                  <Grid item sm={2}>
                                      <Typography align={'right'}>Qty: {item.quantity}</Typography>
                                  </Grid>
                                  <Grid item sm={2}>
                                      <Typography align={'right'}>
                                          {formatCurrencyString({
                                              value: item.quantity * item.price,
                                              currency: 'USD',
                                              language: 'en-US'
                                          })}
                                      </Typography>
                                  </Grid>
                              </Grid>
                          </Box>
                      ))}
                  </Box>
                  <Box marginBottom={3}>
                      <hr style={{borderColor: 'rgba(50, 50, 93, 0.05)'}}/>
                  </Box>
                  <Box marginBottom={3}>
                      <Box className={classes.lineItem}>
                          <Typography>Subtotal</Typography>
                          <Typography>
                              {formatCurrencyString({
                                  value: totalPrice,
                                  currency: 'USD',
                                  language: 'en-US'
                              })}
                          </Typography>
                      </Box>
                      {state.deliveryMethod === 'delivery' && (
                          <Box className={classes.lineItem}>
                              <Typography>Shipping</Typography>
                              {state.shippingMethod && <Typography>${state.shippingMethod.amount}</Typography>}
                              {!state.shippingMethod && <Typography variant={'caption'}>Calculated at next step</Typography>}
                          </Box>
                      )}
                      <Box className={classes.lineItem}>
                          <Typography>Taxes {/(Connecticut|CT)/ig.test(state.billingAddress.state) && '(Connecticut 6.35%)'}</Typography>
                          {state.billingAddress.state && (
                              <Typography>
                                  {formatCurrencyString({
                                      value: orderSummary.salesTax,
                                      currency: 'USD',
                                      language: 'en-US'
                                  })}
                              </Typography>
                          )}
                          {!state.billingAddress.state && <Typography variant={'caption'}>Calculated at next step</Typography>}
                      </Box>
                  </Box>
                  <Box marginBottom={3}>
                      <hr style={{borderColor: 'rgba(50, 50, 93, 0.05)'}}/>
                  </Box>
                  <div className={classes.lineItem}>
                      <Typography>Total</Typography>
                      <Typography>
                          {formatCurrencyString({
                              value: orderSummary.total,
                              currency: 'USD',
                              language: 'en-US'
                          })}
                      </Typography>
                  </div>
              </Grid>
          </Grid>
          <Footer/>
      </div>
  )
}
