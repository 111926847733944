import React, {useEffect, useState} from 'react'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import { navigate } from "gatsby"
import InputBase from '@material-ui/core/InputBase'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import FormLabel from '@material-ui/core/FormLabel'
import CircularProgress from '@material-ui/core/CircularProgress'

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    },
    width: '100%'
  },
  input: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    color: '#525f7f',
    fontSize: 16,
    marginBottom: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
}))(InputBase)

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%'
    },
    '& .MuiFormGroup-row': {
      alignItems: 'center'
    },
    '& .MuiFormControl-root': {
      backgroundColor: '#fff',
      border: 'none',
      borderRadius: 4,
      boxShadow: '0 1px 3px 0 rgba(50, 50, 93, 0.15)',
      display: 'block',
      marginBottom: 20
    },
    '& .MuiFormLabel-root': {
      '&.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.54)'
      }
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  bottom: {
    color: '#999',
    animationDuration: '550ms',
    marginLeft: 10
  },
}))

/*
const StyledLink = styled(Link)`
  background-color: #1976d2;
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.02857em;
  line-height: 1.75;
  min-width: 64px;
  padding: 6px 16px;
  text-decoration: none;
  &:hover {
    background-color: rgb(17, 82, 147);
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  }
`
*/

const AddressSection = ({data, onChange}) => {
  const classes = useStyles()
  const [state, setState] = useState({
    email: '',
    phone: '',
    billingAddress: {
      name: '',
      street1: '',
      city: '',
      state: '',
      zip: '',
      country: 'US'
    }
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const order = sessionStorage.getItem('order')
    console.log(JSON.parse(order))
    setState({...state, ...JSON.parse(order)})
  }, [])

  const handleAddressChange = (event) => {
    const name = event.target.name
    setState({
      ...state,
      billingAddress: {
        ...state.billingAddress,
        [name]: event.target.value
      }
    })
    onChange(state.billingAddress)
  }
  const handleContactChange = (event) => {
    const name = event.target.name
    setState({
      ...state,
      [name]: event.target.value
    })
  }

  const handleSubmit = async () => {
    setLoading(true)

    /*
    const parcels = Object.entries(data.items).reduce((temp, [key, {metadata}]) => {
      console.log(metadata)
      if (metadata.parcel) {
        temp.push(metadata.parcel);
      }
      return temp
    }, [])

    const payload = {
      address: state.billingAddress,
      parcels: parcels
    }

    const response = await fetch(`${process.env.GATSBY_LAMBDA_URL}/.netlify/functions/fromAddress`, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    const results = await response.json();
    */

    /*
    const results = {
      "carrier_accounts": [],
      "object_created": "2020-10-16T17:15:40.540Z",
      "object_updated": "2020-10-16T17:15:40.620Z",
      "object_id": "894a9c5ae8fb45ad9ea7ca680c02772b",
      "object_owner": "anthony.fabrizi@fitouch.com",
      "status": "SUCCESS",
      "address_from": {
        "object_id": "9c99ada19d2a4e94872e26a83ca59730",
        "is_complete": true,
        "name": "Signxperts",
        "company": "Farmington Displays Inc",
        "street_no": "",
        "street1": "21 Hyde Rd",
        "validation_results": {},
        "street2": "",
        "street3": "",
        "city": "Farmington",
        "state": "CT",
        "zip": "06032",
        "country": "US",
        "phone": "8606772497",
        "email": "",
        "is_residential": null,
        "test": true
      },
      "address_to": {
        "object_id": "8c257dc4fcb244be9d454f323ec783c9",
        "is_complete": true,
        "name": "Mr Hippo",
        "company": "",
        "street_no": "",
        "street1": "Broadway 1",
        "validation_results": {},
        "street2": "",
        "street3": "",
        "city": "New York",
        "state": "NY",
        "zip": "10007",
        "country": "US",
        "phone": "",
        "email": "",
        "is_residential": null,
        "test": true
      },
      "parcels": [
        {
          "object_state": "VALID",
          "object_created": "2020-10-16T17:15:40.501Z",
          "object_updated": "2020-10-16T17:15:40.564Z",
          "object_id": "2a1200f05b914b5398dbe77e087ae676",
          "object_owner": "anthony.fabrizi@fitouch.com",
          "template": null,
          "extra": {},
          "length": "50.0000",
          "width": "8.0000",
          "height": "8.0000",
          "distance_unit": "in",
          "weight": "20.0000",
          "mass_unit": "lb",
          "value_amount": null,
          "value_currency": null,
          "metadata": "",
          "line_items": [],
          "test": true
        }
      ],
      "shipment_date": "2020-10-16T17:15:40.619Z",
      "address_return": {
        "object_id": "9c99ada19d2a4e94872e26a83ca59730",
        "is_complete": true,
        "name": "Signxperts",
        "company": "Farmington Displays Inc",
        "street_no": "",
        "street1": "21 Hyde Rd",
        "validation_results": {},
        "street2": "",
        "street3": "",
        "city": "Farmington",
        "state": "CT",
        "zip": "06032",
        "country": "US",
        "phone": "8606772497",
        "email": "",
        "is_residential": null,
        "test": true
      },
      "alternate_address_to": null,
      "customs_declaration": null,
      "extra": {},
      "rates": [
        {
          "object_created": "2020-10-16T17:15:43.022Z",
          "object_id": "51a0edb854df41efa08b77d3ac186216",
          "object_owner": "anthony.fabrizi@fitouch.com",
          "shipment": "894a9c5ae8fb45ad9ea7ca680c02772b",
          "attributes": [],
          "amount": "32.13",
          "currency": "USD",
          "amount_local": "32.13",
          "currency_local": "USD",
          "provider": "UPS",
          "provider_image_75": "https://shippo-static.s3.amazonaws.com/providers/75/UPS.png",
          "provider_image_200": "https://shippo-static.s3.amazonaws.com/providers/200/UPS.png",
          "servicelevel": {
            "name": "3 Day Select®",
            "token": "ups_3_day_select",
            "terms": ""
          },
          "estimated_days": 3,
          "arrives_by": null,
          "duration_terms": "Delivery by the end of the third business day.",
          "messages": [],
          "carrier_account": "27e7019368e04c1f8637162dbe7cd7fa",
          "test": true,
          "zone": null
        },
        {
          "object_created": "2020-10-16T17:15:43.021Z",
          "object_id": "8b39e3d4564b4e708a1aac14dbfbd189",
          "object_owner": "anthony.fabrizi@fitouch.com",
          "shipment": "894a9c5ae8fb45ad9ea7ca680c02772b",
          "attributes": [],
          "amount": "46.70",
          "currency": "USD",
          "amount_local": "46.70",
          "currency_local": "USD",
          "provider": "UPS",
          "provider_image_75": "https://shippo-static.s3.amazonaws.com/providers/75/UPS.png",
          "provider_image_200": "https://shippo-static.s3.amazonaws.com/providers/200/UPS.png",
          "servicelevel": {
            "name": "2nd Day Air®",
            "token": "ups_second_day_air",
            "terms": ""
          },
          "estimated_days": 2,
          "arrives_by": null,
          "duration_terms": "Delivery by the end of the second business day.",
          "messages": [],
          "carrier_account": "27e7019368e04c1f8637162dbe7cd7fa",
          "test": true,
          "zone": null
        },
        {
          "object_created": "2020-10-16T17:15:43.020Z",
          "object_id": "738c624b55024dc488e952334e31c93d",
          "object_owner": "anthony.fabrizi@fitouch.com",
          "shipment": "894a9c5ae8fb45ad9ea7ca680c02772b",
          "attributes": [],
          "amount": "51.63",
          "currency": "USD",
          "amount_local": "51.63",
          "currency_local": "USD",
          "provider": "UPS",
          "provider_image_75": "https://shippo-static.s3.amazonaws.com/providers/75/UPS.png",
          "provider_image_200": "https://shippo-static.s3.amazonaws.com/providers/200/UPS.png",
          "servicelevel": {
            "name": "2nd Day Air® A.M.",
            "token": "ups_second_day_air_am",
            "terms": ""
          },
          "estimated_days": 2,
          "arrives_by": "10:30:00",
          "duration_terms": "Delivery on the second business day by 10:30 a.m. or 12:00 noon to most commercial destinations.",
          "messages": [],
          "carrier_account": "27e7019368e04c1f8637162dbe7cd7fa",
          "test": true,
          "zone": null
        },
        {
          "object_created": "2020-10-16T17:15:43.020Z",
          "object_id": "cda840ad957c4a93b7c983a7c1018b30",
          "object_owner": "anthony.fabrizi@fitouch.com",
          "shipment": "894a9c5ae8fb45ad9ea7ca680c02772b",
          "attributes": [
            "BESTVALUE",
            "CHEAPEST"
          ],
          "amount": "15.72",
          "currency": "USD",
          "amount_local": "15.72",
          "currency_local": "USD",
          "provider": "UPS",
          "provider_image_75": "https://shippo-static.s3.amazonaws.com/providers/75/UPS.png",
          "provider_image_200": "https://shippo-static.s3.amazonaws.com/providers/200/UPS.png",
          "servicelevel": {
            "name": "Ground",
            "token": "ups_ground",
            "terms": ""
          },
          "estimated_days": 1,
          "arrives_by": null,
          "duration_terms": "Delivery times vary. Delivered usually in 1-5 business days.",
          "messages": [],
          "carrier_account": "27e7019368e04c1f8637162dbe7cd7fa",
          "test": true,
          "zone": null
        },
        {
          "object_created": "2020-10-16T17:15:43.019Z",
          "object_id": "84eee74aa7674d4e97413523f65176b8",
          "object_owner": "anthony.fabrizi@fitouch.com",
          "shipment": "894a9c5ae8fb45ad9ea7ca680c02772b",
          "attributes": [],
          "amount": "67.58",
          "currency": "USD",
          "amount_local": "67.58",
          "currency_local": "USD",
          "provider": "UPS",
          "provider_image_75": "https://shippo-static.s3.amazonaws.com/providers/75/UPS.png",
          "provider_image_200": "https://shippo-static.s3.amazonaws.com/providers/200/UPS.png",
          "servicelevel": {
            "name": "Next Day Air Saver®",
            "token": "ups_next_day_air_saver",
            "terms": ""
          },
          "estimated_days": 1,
          "arrives_by": "15:00:00",
          "duration_terms": "Next business day delivery by 3:00 or 4:30 p.m. for commercial destinations and by end of day for residentail destinations.",
          "messages": [],
          "carrier_account": "27e7019368e04c1f8637162dbe7cd7fa",
          "test": true,
          "zone": null
        },
        {
          "object_created": "2020-10-16T17:15:43.018Z",
          "object_id": "c89f75ec44eb43849b2963444818b1c5",
          "object_owner": "anthony.fabrizi@fitouch.com",
          "shipment": "894a9c5ae8fb45ad9ea7ca680c02772b",
          "attributes": [],
          "amount": "73.88",
          "currency": "USD",
          "amount_local": "73.88",
          "currency_local": "USD",
          "provider": "UPS",
          "provider_image_75": "https://shippo-static.s3.amazonaws.com/providers/75/UPS.png",
          "provider_image_200": "https://shippo-static.s3.amazonaws.com/providers/200/UPS.png",
          "servicelevel": {
            "name": "Next Day Air®",
            "token": "ups_next_day_air",
            "terms": ""
          },
          "estimated_days": 1,
          "arrives_by": "10:30:00",
          "duration_terms": "Next business day delivery by 10:30 a.m., 12:00 noon, or end of day, depending on destination.",
          "messages": [],
          "carrier_account": "27e7019368e04c1f8637162dbe7cd7fa",
          "test": true,
          "zone": null
        },
        {
          "object_created": "2020-10-16T17:15:43.017Z",
          "object_id": "2cf57afea2f84b5a8fc9688d07122891",
          "object_owner": "anthony.fabrizi@fitouch.com",
          "shipment": "894a9c5ae8fb45ad9ea7ca680c02772b",
          "attributes": [
            "FASTEST"
          ],
          "amount": "104.55",
          "currency": "USD",
          "amount_local": "104.55",
          "currency_local": "USD",
          "provider": "UPS",
          "provider_image_75": "https://shippo-static.s3.amazonaws.com/providers/75/UPS.png",
          "provider_image_200": "https://shippo-static.s3.amazonaws.com/providers/200/UPS.png",
          "servicelevel": {
            "name": "Next Day Air® Early",
            "token": "ups_next_day_air_early_am",
            "terms": ""
          },
          "estimated_days": 1,
          "arrives_by": "08:30:00",
          "duration_terms": "Next business day delivery by 8:30 a.m., 9:00 a.m., or 9:30 a.m. ",
          "messages": [],
          "carrier_account": "27e7019368e04c1f8637162dbe7cd7fa",
          "test": true,
          "zone": null
        }
      ],
      "messages": [
        {
          "source": "UPS",
          "code": "110971",
          "text": "RatedShipmentAlert: Your invoice may vary from the displayed reference rates"
        },
        {
          "source": "UPS",
          "code": "119001",
          "text": "RatedShipmentAlert: Additional Handling has automatically been set on Package 1."
        },
        {
          "source": "DHLExpress",
          "code": "",
          "text": "Shippo's DHL Express master account doesn't support shipments to inside of the US"
        }
      ],
      "metadata": "",
      "test": true,
      "order": null
    };
    */
    let order = JSON.parse(sessionStorage.getItem('order'))
    order = {...order, email: state.email, phone: state.phone, billingAddress: state.billingAddress};
    sessionStorage.setItem('order', JSON.stringify(order))
    // sessionStorage.setItem('shippingMethods', JSON.stringify(results))
    navigate("/checkout/delivery-method")
  }

  return (
    <div className={classes.root}>
      <Box marginBottom={5}>
        <Typography variant={'h6'} gutterBottom>Contact Information</Typography>
        <FormLabel>
          <BootstrapInput type='email' name='email' value={state.email} onChange={handleContactChange} placeholder='Email' required />
        </FormLabel>
        <FormLabel>
          <BootstrapInput type='tel' name='phone' value={state.phone} onChange={handleContactChange} placeholder='Phone' required />
        </FormLabel>
      </Box>
      <Box marginBottom={5}>
        <Typography variant={'h6'} gutterBottom>Billing Address</Typography>
        <FormLabel>
          <BootstrapInput name='name' value={state.billingAddress.name} onChange={handleAddressChange} placeholder='Name' required />
        </FormLabel>
        <FormLabel>
          <BootstrapInput name='street1' value={state.billingAddress.street1} onChange={handleAddressChange} placeholder='Address' required />
        </FormLabel>
        <FormLabel>
          <BootstrapInput name='city' value={state.billingAddress.city} onChange={handleAddressChange} placeholder='City' required />
        </FormLabel>
        <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
          <Grid item sm={6}>
            <FormLabel>
              <BootstrapInput name='state' value={state.billingAddress.state} onChange={handleAddressChange} placeholder='State' required />
            </FormLabel>
          </Grid>
          <Grid item sm={6}>
            <FormLabel>
              <BootstrapInput name='zip' value={state.billingAddress.zip} onChange={handleAddressChange} placeholder='Postal code' required />
            </FormLabel>
          </Grid>
        </Grid>
        {/*
        <FormLabel>
          <Typography variant={'inherit'}>Country</Typography>
          <NativeSelect
            id='demo-customized-select-native'
            onChange={onChange}
            input={<BootstrapInput />}
            defaultValue={'US'}
          >
            <option value='AU'>Australia</option>
            <option value='AT'>Austria</option>
            <option value='BE'>Belgium</option>
            <option value='BR'>Brazil</option>
            <option value='CA'>Canada</option>
            <option value='CN'>China</option>
            <option value='DK'>Denmark</option>
            <option value='FI'>Finland</option>
            <option value='FR'>France</option>
            <option value='DE'>Germany</option>
            <option value='HK'>Hong Kong</option>
            <option value='IE'>Ireland</option>
            <option value='IT'>Italy</option>
            <option value='JP'>Japan</option>
            <option value='LU'>Luxembourg</option>
            <option value='MY'>Malaysia</option>
            <option value='MX'>Mexico</option>
            <option value='NL'>Netherlands</option>
            <option value='NZ'>New Zealand</option>
            <option value='NO'>Norway</option>
            <option value='PL'>Poland</option>
            <option value='PT'>Portugal</option>
            <option value='SG'>Singapore</option>
            <option value='ES'>Spain</option>
            <option value='SE'>Sweden</option>
            <option value='CH'>Switzerland</option>
            <option value='GB'>United Kingdom</option>
            <option value='US'>United States</option>
          </NativeSelect>
        </FormLabel>
        */}
      </Box>
      <Box marginBottom={5} align={'right'}>
        <Button variant={'contained'} color={'primary'} disabled={loading} onClick={handleSubmit}>
          Continue
          {loading && (
              <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  className={classes.bottom}
                  size={20}
                  thickness={4}
              />
          )}
        </Button>
      </Box>
    </div>
  )
}

export default AddressSection
