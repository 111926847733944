import React, {Fragment} from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const Success = () => {
  return (
    <Fragment>
      <Typography variant={'h2'}>Success!</Typography>
      <Typography>Thanks for your order! A receipt will be emailed to you shortly. If you have any questions, please email <a href='mailto:orders@fdi-group.com'>orders@fdi-group.com</a>.</Typography>
      <Button variant={'contained'} color={'primary'} href='/'>
        Back to Home
      </Button>
    </Fragment>
  )
}

export default Success
